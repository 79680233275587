@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  background: white;
  border-bottom: 1px solid $color-gray-400;

  .toolbar-button {
    margin-right: 8px;
  }

  .picker-wrapper {
    position: relative;

    .picker {
      position: absolute;
      top: 40px;
    }
  }
}
