@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "ats-editor/src/component/editor/setting/common/minxin";

.container {
  position: relative;
  display: flex;

  .sliderWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0;
    transform: translate(0, 10px);

    &.isCapture{
      opacity: 1;
      transform: translate(0, 0);
    }

    &.fadeIn {
      animation: fadeIn .4s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

