@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container{
  width: 100%;

  &:not(.CONTAINER) {
    padding: 0 24px;
  }

  .arrows {
    margin: 0 auto;
    max-width: 1216px;
    display: flex;
    gap: 12px;

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      z-index: 1;
      cursor: pointer;
      background: transparent;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
      &:not(:disabled):hover {
        background-color: $color-gray-100;
      }

      &.darkMode {
        &:not(:disabled):hover {
          background-color: rgba(233, 236, 239, 0.10);
        }
      }
    }

    &.TOP {
      justify-content: flex-end;
      padding-bottom: 16px;
    }
    &.BOTTOM {
      justify-content: center;
      padding-top: 16px;
    }
  }
}