@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  z-index: 1;

  position: relative;
  width: 100%;

  .slide-list {
    display: flex;
    overflow: visible;
    width: 100%;

    transition: transform 0.2s ease-in-out;
  }

  .slide-item {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
