@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.wrapper {
  display: flex;
  overflow: hidden;

  &.MOBILE {
    max-height: 800px;
  }

  .imageSizeWrap {
    position: relative;
    justify-content: center;
    width: 100%;

    &.cursor {
      cursor: pointer;

      &:hover .media {
        transform: scale(1.02);
      }
    }

    &.RATIO {
      max-width: 1760px;
    }

    .media-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .media {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s transform;

        &.capture {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
