@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 24px;

  &.TOP {
    align-items: start;
  }
  &.CENTER {
    align-items: center;
  }
  &.BOTTOM {
    align-items: end;
  }

  .content {
    position: relative;
    min-width: 293px;
    min-height: 40px;
    word-break: keep-all;
  }
}
