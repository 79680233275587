@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: absolute;
  background: white;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid $color-gray-400;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px #0000001a;
  box-shadow: 0px 0px 6px 0px #0000000f;
  display: none;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 8px;
  }

  .divider {
    width: 1px;
    height: 20px;
    background: $color-gray-400;
    margin-right: 8px;
  }
}
