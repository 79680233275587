@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 40px;
  margin-right: 8px;
  border-radius: 4px;
  padding: 0 16px;
  background-color: $color-gray-100;
  border: 1px solid transparent;
  @include font('b3');
  transition: 0.4s all;
  z-index: 1;
  cursor: pointer;

  &.active {
    border: 1px solid $color-gray-800;
    box-shadow: 0 0 0 1px $color-gray-800;
    background-color: $color-white;
  }

  &.placeholder {
    color: $color-gray-600;
  }

  .dropdown-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid $color-gray-400;
    box-shadow: 0px 4px 6px 0px #0000000d;
    box-shadow: 0px 6px 15px 0px #00000026;
    padding: 10px 6px;
    border-radius: 4px;
    overflow: hidden;

    .dropdown-list {
      width: 100%;
      overflow-y: scroll;
      @include hidden-scroll;
      max-height: 180px;

      .page {
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;
        padding: 0 10px;
        border-radius: 4px;

        @include font('b3');
        color: $color-gray-600;

        &.active {
          font-weight: 700;
        }

        &:hover {
          background: $color-gray-80;
        }
      }
    }
  }
}
