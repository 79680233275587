@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "ui/styles/colors";

.stepper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  background-color: $color-white;
  border: 1px solid $color-gray-400;

  & > .stepper__svg {
    fill: $color-gray-800
  }

  &:hover {
    border: 1px solid $color-gray-800;
    & > .stepper__svg {
      fill: $color-gray-800
    }
  }

  &.isFocused {
    border: 1px solid $color-gray-800;
    & > .stepper__svg {
      fill: $color-gray-800
    }
  }

  &:disabled {
    border: 1px solid $color-gray-400;
    & > .stepper__svg {
      fill: $color-gray-400
    }
  }
}

.stepper {
  &.sm {
    width: 24px;
    height: 24px;
  }
  &.md {
    width: 32px;
    height: 32px;
  }
  &.lg {
    width: 40px;
    height: 40px;
  }
}
