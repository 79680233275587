@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: relative;
}

.image-container {
  display: flex;

  .image-wrapper {
    position: relative;
    display: inline-block;
    border: 1px solid transparent;

    &.active {
      border: 1px solid $color-guide;
      box-shadow: 0 0 0 1px $color-guide;
    }
  }
}

.image-util {
  position: absolute;
  left: 0;
  top: -50px;
  background: white;
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid $color-gray-400;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px #0000001a;
  box-shadow: 0px 0px 6px 0px #0000000f;

  .radio-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .radio {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid transparent;
      @include font('b3');
      font-weight: 700;
      cursor: pointer;

      &:hover,
      &.active {
        border: 1px solid $color-gray-300;
        box-shadow: 0 0 0 1px $color-gray-300;
      }
    }
  }

  .divider {
    width: 1px;
    height: 20px;
    background: $color-gray-300;
    margin: 0 8px;
  }

  .custom-size {
    display: flex;
    align-items: center;
    gap: 8px;

    .align_icon_button {
      &.active {
        background: $color-gray-100;
        box-shadow: 0 0 0 2px $color-gray-500;
      }
    }

    .style-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      &.disabled:after {
        color: $color-gray-400;
      }

      &:after {
        content: 'px';
        position: absolute;
        top: 5px;
        right: 8px;
        @include font('b3');
      }

      .style-text {
        width: 68px;
      }
    }

    .border-text {
      width: 45px;
    }
  }
}
