@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'mixins';
@import 'ui/styles/colors';

.button {
  padding: 0;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > svg {
    pointer-events: none;
    flex-shrink: 0;
  }

  &:focus-visible {
    border-color: $color-gray-500;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.ghost {
  &:hover:not(:disabled) {
    background-color: $color-gray-100;
  }

  &:focus-visible:not(:disabled) {
    background-color: $color-gray-100;
  }

  &:disabled {
    > svg {
      fill: $color-gray-400;
    }
  }
}

.negative {
  &:hover:not(:disabled) {
    > svg {
      fill: $color-white;
    }
  }

  &:disabled {
    > svg {
      fill: $color-gray-700;
    }
  }
}

.tealGhost {
  &:hover:not(:disabled) {
    fill: $color-teal-600;
    background-color: $color-gray-100;
  }

  &:focus-visible:not(:disabled) {
    fill: $color-teal-600;
    background-color: $color-gray-100;
  }

  &:disabled {
    > svg {
      fill: $color-gray-400;
    }
  }
}
