@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-gray-80;
  border-radius: 6px;

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;

    &.border-2 {
      width: 132px;
    }

    &.border-3 {
      width: 86px;
    }

    &.border-4 {
      width: 62px;
    }

    &.border-5 {
      width: 48px;
    }

    &.size-sm {
      height: 32px;
    }

    &.size-lg {
      height: 48px;
    }

    &:hover {
      font-weight: 700;
    }

    &.active {
      background-color: $color-white;
      border: 2px solid $color-gray-300;
      font-weight: 700;
    }

    &.margin {
      margin-right: 0;
    }
  }
}
