@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import './mixins';
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapper {
    display: flex;
    align-items: center;
    border-radius: 6px;
    gap: 8px;
    // border: 2px solid transparent;

    @include colors-bg($color-gray-80, $color-gray-100, $color-gray-100);

    &:focus-within {
      @include colors-bg($color-white, $color-white, $color-white);
      box-shadow: 0 0 0 2px inset $color-gray-800;
    }

    &.disabled {
      cursor: default;
      border: 0;
      @include colors-bg($color-gray-100, $color-gray-100, $color-gray-100);
    }

    &.readonly {
      &:focus-within {
        @include colors-bg($color-gray-80, $color-gray-100, $color-gray-100);
        box-shadow: none;
      }
    }

    &.error {
      box-shadow: 0 0 0 2px inset $color-red-600;
    }

    .input {
      width: 100%;
      flex: 1;
      border: none;
      @include colors-bg(transparent, transparent, transparent);
      @include font('b3');
      color: $color-gray-800;

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }

      &.ellipsis {
        text-overflow: ellipsis;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        @include font('b3');
        opacity: 1;
        color: $color-gray-400;
      }

      &::placeholder {
        @include font('b3');
        opacity: 1;
        color: $color-gray-600;
      }
    }

    // ---------------------- size --------------------- //
    &.lg {
      height: 48px;
      padding: 0 16px;
    }

    &.md {
      height: 40px;
      padding: 0 10px;
    }

    &.sm {
      height: 32px;
      padding: 0 10px;
    }

    &.icon {
      padding: 0 10px;
      gap: 4px;
    }
  }

  > p.error {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.4px;
    color: $color-red-600;
  }
}
