@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  &.full-width {
    width: 100%;
  }
}
.editor {
  height: 100%;

  .highlight {
    padding: 0 6px;
    color: white;
    background-color: $color-gray-800;
    border-radius: 4px;
  }

  .placeholder {
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 104px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: $color-gray-400;
    user-select: none;
    padding: 10px;
  }

  .italic {
    font-style: italic;
  }

  .link {
    &:hover {
      text-decoration: underline;
    }

    cursor: pointer;
  }

  .image-wrapper {
    position: relative;
    display: inline-block;
    border: 1px solid transparent;

    &.active {
      border: 1px solid $color-guide;
      box-shadow: 0 0 0 1px $color-guide;
    }
  }
}
