@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import '../../../../../node_modules/ui/styles/colors';

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 4px;

  &.active {
    box-shadow: 0 0 0 2px $color-gray-500;
    background: $color-gray-100;
  }

  .hyper-wrapper {
    position: absolute;
    top: 30px;
    right: -17px;
    padding: 10px 16px !important;
    width: 336px;
    border: 1px solid $color-gray-400;
    box-shadow: 0px 2px 6px 0px #0000004d;
    box-shadow: 0px 0px 20px 0px #0000001a;
    border-radius: 8px;
    z-index: 160;
    background: white;

    .tab {
      width: 100%;
      margin-bottom: 14px;
    }

    .bottom {
      display: flex;

      .dropdown-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .save {
          min-width: 58px;

          > span {
            white-space: nowrap;
          }
        }
      }

      .url-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .input {
          width: 100%;
        }

        .url-button {
          min-width: 58px;
          margin-left: 8px;
        }
      }
    }
  }
}