@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 300px;
  padding: 6px;
  border-radius: 17px;
  background-color: rgba(0, 0, 0, 0.1);

  color: $color-white;

  .navigator {
    height: 24px;
    cursor: pointer;

    &.disabled {
      cursor: default;
      > svg {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-left: 12px;

    .indicator__count {
      @include font('b2');

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      width: 40px;
    }
    .indicator__auto-switch {
      height: 24px;
      cursor: pointer;
    }
  }
}
