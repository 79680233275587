@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  flex-direction: column;


  .default-container {
    &.float {
      position: absolute;
      border: 1px solid $color-gray-400;
      border-radius: 4px;
      background: white;
      z-index: 150;
      filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
      display: inline-flex;
      align-items: center;
      padding: 12px 16px;
    }

    &.solid {
      position: relative;
      display: flex;
      width: 100%;
      padding: 8px 16px;
      border-bottom: 1px solid $color-gray-400;
      background: white;
      border-radius: 4px 0;
    }

    .default-wrapper {
      display: inline-flex;
      align-items: center;

      .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 25px;

        &:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 20px;
          background: $color-gray-300;
          left: -12px;
        }

        &:first-child:after {
          content: '';
          width: 0;
          height: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .customToolbar {
    position: relative;
  }
}
