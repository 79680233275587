@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 98px;
  background: white;
  border-radius: 0 4px;
  padding: 8px 16px;
  border-top: 1px solid $color-gray-400;

  .group {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;

    &:first-child {
      border-bottom: 1px solid $color-gray-400;
    }
  }

  .block {
    position: relative;
    display: flex;
    align-items: center;
    
    .wrapper {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 24px;
      height: 32px;

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        right: -12px;
        width: 1px;
        height: 20px;
        background: $color-gray-300;
      }

      &:last-child:after {
        content: '';
        width: 0;
        height: 0;
      }

      .label {
        margin-right: 12px;
      }

      .size-picker {
        width: 59px;
      }

      .color-picker {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: 2px solid $color-gray-400;
        margin-right: 8px;
      }

      .align {
        display: flex;

        .rotate {
          transform: rotate(180deg);
        }
      }

      .border-input {
        width: 40px;
      }

      .color-input {
        width: 80px;
      }

      .url {
        margin-right: 8px;
      }

      .link {
        width: 400px;
      }
    }
  }
}

.error {
  margin-left: 8px;
  color: $color-red-600;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
