@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.card {
  position: relative;
  box-sizing: border-box;

  &.MOBILE {
    &.CONTAINER {
      flex: 0 0 100%;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    .image {
      overflow: hidden;

      &.has-link {
        cursor: pointer;
      }

      img {
        object-fit: cover;
      }

      .capture {
        width: 100%;
        height: 100%;

        &.capture {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
  }

  &.OVERLAY {
    .text-container {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;

      .text-editor-wrapper {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}