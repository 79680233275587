@mixin colors-bg($default, $hover, $disabled) {
  background: $default;

  &:not(:disabled) {
    &:hover {
      background: $hover;
    }
  }

  &:disabled {
    background: $disabled;
  }
}